import { combineReducers } from 'redux';
import { configureStore, createAsyncThunk } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import OdieReducer from './OdieReducer';
import MatterReducer from './MatterReducer';
import AppReducer from './AppReducer';
import UserReducer from './UserReducer';

export const history = createBrowserHistory();
const middleware = routerMiddleware(history);

const reducer = combineReducers({
  odie: OdieReducer,
  matter: MatterReducer,
  app: AppReducer,
  user: UserReducer,
  router: connectRouter(history),
});

// Add persist
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['odie'],
};
const persistedReducer = persistReducer(persistConfig, reducer);

export const reduxStore = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck:
        process.env.NODE_ENV === 'test'
          ? false
          : {
              ignoredPaths: ['odie'],
              ignoredActions: ['UPDATE_ODIE_ACTION', 'persist/PERSIST'],
            },
    }).concat(middleware),
});

export type RootState = ReturnType<typeof reducer>;

export type AppDispatch = typeof reduxStore.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(reduxStore);

export const createAppAsyncThunk =
  createAsyncThunk.withTypes<{
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }>();
